import { Select, Tag } from "antd";
import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../../context/DataContext";
import { CounterProps } from "../../../../types/interfaces";

interface Props {
  tags: CounterProps[];
  site_id: string;
}

export const SiteCounterList = ({ tags, site_id }: Props) => {
  const { handleEditData, counterOptions, handleCreateData } =
    useContext(DataContext);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<any>([{ label: "", value: "" }]);

  useEffect(() => {
    const selectedOptions = tags.map(
      (tag) => tag.counter_detail.active && tag.id
    );
    let newOptions = [];
    for (let i = 0; i < counterOptions.length; i++) {
      newOptions.push({
        value: counterOptions[i].id,
        label: counterOptions[i].description,
        disabled: selectedOptions.includes(counterOptions[i].id),
      });
    }
    setOptions(newOptions);
  }, [counterOptions, tags]);

  const handleDeleteTag = (removedTag: CounterProps) => {
    handleEditData("sitecounter", {
      ...removedTag.counter_detail,
      active: false,
    });
  };

  const handleInputConfirm = () => {
    if (inputValue) {
      const data = tags.filter((tag) => tag.id === inputValue);
      if (data.length > 0) {
        handleEditData("sitecounter", {
          ...data[0].counter_detail,
          active: true,
        });
      } else {
        handleCreateData("sitecounter", { counter_id: inputValue, site_id });
      }
      setInputValue("");
    }
  };

  const forMap = (tag: CounterProps) => {
    const tagElem = (
      <Tag
        style={{ margin: "2px" }}
        closable
        onClose={(e) => {
          e.preventDefault();
          handleDeleteTag(tag);
        }}
      >
        {tag.description}
      </Tag>
    );
    if (tag.counter_detail.active)
      return (
        <span key={tag.id} style={{ display: "inline-block" }}>
          {tagElem}
        </span>
      );
  };

  const tagChild = tags.map(forMap);

  return (
    <>
      <div style={{ marginBottom: 16, marginTop: 16 }}>{tagChild}</div>
      {options.length > 0 && (
        <Select
          placeholder="Add Counter"
          size="small"
          value={inputValue}
          style={{ width: "8rem" }}
          onChange={(value) => setInputValue(value)}
          options={options}
          onBlur={handleInputConfirm}
        />
      )}
    </>
  );
};
