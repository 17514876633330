import {
  AdminDataProps,
  CounterListProps,
  CounterProps,
  CounterTableProps,
  EditableUserProps,
  EntryProps,
  ListProps,
  SiteProps,
  SiteTableProps,
  UserDataProps,
  UserProps,
  UserTableProps,
} from "../types/interfaces";

export const USER_INITIAL_STATE: UserDataProps = {
  id: "",
  username: "",
  role: 0,
  is_admin: false,
};

export const SITE_LIST_INITIAL_STATE: ListProps[] = [
  {
    label: "",
    value: "",
  },
];

export const COUNTERS: CounterProps[] = [
  {
    id: "0",
    description: "",
    details: { color: "#FFF" },
    counter_detail: {
      id: 0,
      current_value: 0,
    },
  },
];

export const COUNTER_INITIAL_STATE: CounterProps = {
  id: "",
  description: "",
  details: { color: "#FFF" },
  counter_detail: {
    id: 0,
    current_value: 0,
  },
};

export const COUNTER_TABLE_INITIAL_STATE: CounterTableProps = {
  id: 0,
  counter_id: "",
  current_value: 0,
  active: true,
  counter: COUNTER_INITIAL_STATE,
  site: {
    id: "",
    name: "",
  },
};

export const SITES: SiteProps[] = [
  {
    id: "0",
    name: "",
    counters: COUNTERS,
  },
  {
    id: "2",
    name: "",
    counters: COUNTERS,
  },
];

export const USERS: UserProps[] = [
  {
    id: "",
    username: "",
    sites: SITES,
  },
];

export const ADMIN_DATA_INITIAL_STATE: AdminDataProps = {
  sites: [],
  users: [],
  counters: [],
  counterList: [],
};

export const ENTRIES_INITIAL_STATE: EntryProps[] = [
  {
    id: 0,
    in_out: true,
    quantity: 0,
    new_value: 0,
    created_at: new Date(),
    counter: COUNTERS[0],
    site: SITES[0],
    user: USERS[0],
  },
];

export const SITE_INITIAL_STATE: SiteTableProps = {
  id: "",
  name: "",
  counters: [],
  users: [],
};

export const USER_LIST_INITIAL_STATE: UserTableProps[] = [
  {
    id: "",
    username: "",
    role: { id: 1, description: "", is_administrative: false, active: false },
  },
];

export const SITE_OPT_INITIAL_STATE: SiteProps[] = [
  {
    id: "",
    name: "",
    counters: [],
  },
];

export const COUNTER_LIST_INITIAL_STATE: CounterListProps[] = [
  {
    id: "0",
    description: "",
    details: null,
  },
];

export const EDITABLE_USER_INITIAL_STATE: EditableUserProps = {
  id: "",
  username: "",
  role: { id: 1, description: "user", is_administrative: false, active: false },
  selectedRole: 1,
  password1: "",
  password2: "",
};
