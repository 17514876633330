import React, { ReactNode } from "react";
import { Col, Layout, Row, theme } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { DisplayComponent } from "./DisplayComponent";
import { AppHeader } from "../components/AppHeader";
import { AppContent } from "../components/AppContent";
import logo from "../../assets/images/company_logo_mobile.png";
import logo2 from "../../assets/images/company_logo_2.png";

interface Props {
  children: ReactNode;
}
export const ProtectedLayout = ({ children }: Props) => {
  const {
    token: { colorFillSecondary },
  } = theme.useToken();

  return (
    <>
      <Layout>
        <Header
          style={{
            padding: 10,
            background: colorFillSecondary,
            minHeight: "8vh",
          }}
        >
          <AppHeader />
        </Header>
        <DisplayComponent error={false} isLoading={false}>
          <Content>
            <AppContent>{children}</AppContent>
          </Content>
        </DisplayComponent>

        <Footer
          style={{
            textAlign: "center",
            color: "#000",
            minHeight: "12vh",
          }}
        >
          <Row gutter={[24, 0]} justify="center">
            <Col lg={2} xs={8} md={4}>
              <img src={logo} width={"80%"} alt="Company Logo" />
            </Col>
            <Col lg={2} xs={8} md={4}>
              <img src={logo2} width={"80%"} alt="Company Logo" />
            </Col>
          </Row>
          <Row gutter={[24, 0]} justify="center" style={{ marginTop: "5px" }}>
            ©Copyright Quattro Software, {new Date().getFullYear()}
          </Row>
        </Footer>
      </Layout>
    </>
  );
};
