import React, { useEffect } from "react";
import { useState } from "react";
import DataContext from "./DataContext";
import { useRequest } from "../hooks";
import {
  AdminDataProps,
  CounterListProps,
  CounterProps,
  EntryProps,
  ListProps,
  SiteProps,
  UserProps,
} from "../types/interfaces";
import {
  ADMIN_DATA_INITIAL_STATE,
  COUNTER_LIST_INITIAL_STATE,
  ENTRIES_INITIAL_STATE,
  SITE_LIST_INITIAL_STATE,
  SITE_OPT_INITIAL_STATE,
  USER_LIST_INITIAL_STATE,
} from "../utils/data";
import dayjs from "dayjs";
import exportFromJSON from "export-from-json";

interface Props {
  children: JSX.Element | JSX.Element[];
}

const INITIAL_ERROR = "Oops, Something has ocurred!";
const INITIAL_SUCCESS = "Successful operation!";

const DataProvider = ({ children }: Props) => {
  const { handleRequest } = useRequest();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isModalReport, setIsModalReport] = useState(false);
  const [successMessage, setSucessMessage] = useState(INITIAL_SUCCESS);
  const [errorMessage, setErrorMessage] = useState(INITIAL_ERROR);
  const [counters, setCounters] = useState<CounterProps[]>([]);
  const [entriesList, setEntriesList] = useState<EntryProps[]>(
    ENTRIES_INITIAL_STATE
  );
  const [allSites, setAllSites] = useState<SiteProps[]>([]);
  const [siteList, setSiteList] = useState<ListProps[]>(
    SITE_LIST_INITIAL_STATE
  );
  const [adminData, setAdminData] = useState<AdminDataProps>(
    ADMIN_DATA_INITIAL_STATE
  );
  const [userOptions, setUserOptions] = useState<UserProps[]>(
    USER_LIST_INITIAL_STATE
  );
  const [siteOptions, setSiteOptions] = useState<SiteProps[]>(
    SITE_OPT_INITIAL_STATE
  );
  const [counterOptions, setCounterOptions] = useState<CounterListProps[]>(
    COUNTER_LIST_INITIAL_STATE
  );
  const [idSite, setIdSite] = useState("");

  useEffect(() => {
    setIsLoading(false);
    setErrorMessage(INITIAL_ERROR);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsSuccess(false);
      setIsError(false);
    }, 5000);
  }, [isError, isSuccess]);

  useEffect(() => {
    const newList = allSites.map((site) => {
      return {
        label: site.name,
        value: site.id,
      };
    });
    setSiteList(newList);
  }, [allSites]);

  const fetchAdminData = () => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest<AdminDataProps>({
      endpoint: `data/`,
      options,
      onSuccess: (response) => {
        const data: AdminDataProps = response.data;
        setAdminData(data);
        setUserOptions(data.users);
        setSiteOptions(data.sites);
        setCounterOptions(data.counterList);
        setIsLoading(false);
      },
      onError: () => {
        setIsError(true);
        setIsLoading(false);
      },
    });
  };

  const fetchCounters = (idUser: string) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest<any>({
      endpoint: `site/all/${idUser}`,
      options,
      onSuccess: (response) => {
        const siteList: SiteProps[] = response.data;
        if (siteList.length > 0) {
          setAllSites(siteList);
          setIdSite(siteList[0].id);
          setCounters(siteList[0].counters);
        }
        setIsLoading(false);
      },
      onError: () => {
        setIsError(true);
        setIsLoading(false);
      },
    });
  };

  const fetchHistoryBySite = (siteArgs?: string, filterDate?: string) => {
    const site = siteArgs || idSite;
    if (site) {
      setIsLoading(true);
      let options: RequestInit = {
        method: "GET",
      };
      let date = filterDate || dayjs();
      handleRequest<any>({
        endpoint: `entry/all/${site}?date=${date}`,
        options,
        onSuccess: (response) => {
          setEntriesList(response.data);
          setIsLoading(false);
        },
        onError: () => {
          setIsError(true);
          setIsLoading(false);
        },
      });
    } else {
      setIsError(true);
      setErrorMessage("Select a Site to get the history records");
    }
  };

  const fetchCounterBySite = (idSite: string) => {
    setIdSite(idSite);
    setIsLoading(true);
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest<any>({
      endpoint: `site/${idSite}`,
      options,
      onSuccess: (response) => {
        const site: SiteProps = response.data;
        setCounters(site.counters);
        setIsLoading(false);
      },
      onError: () => {
        setIsError(true);
        setIsLoading(false);
      },
    });
  };

  const handleAddOrSubstract = (
    add: boolean,
    counter: CounterProps,
    idUser: string,
    qty: number
  ) => {
    setIsLoading(true);
    const data = {
      in_out: add,
      counter_id: counter.id,
      quantity: qty,
      site_id: idSite,
      created_by: idUser,
      site_counter_id: counter.counter_detail.id,
    };
    let options: RequestInit = {
      method: "POST",
      body: JSON.stringify(data),
    };
    handleRequest<any>({
      endpoint: "entry",
      options,
      onSuccess: (response) => {
        if (response.data) {
          fetchCounterBySite(idSite);
          setIsSuccess(true);
          add ? setSucessMessage("+" + qty) : setSucessMessage("-" + qty);
        } else {
          setIsError(true);
          setErrorMessage("An error ocurred, please try again later");
        }
        setIsLoading(false);
      },
      onError: () => {
        setIsError(true);
        setIsLoading(false);
        setErrorMessage("An error ocurred, please try again later");
      },
    });
  };

  const handleCreateData = (
    endpoint: "users" | "counter" | "site" | "sitecounter" | "usersite",
    data: any
  ) => {
    let options: RequestInit = {
      method: "POST",
      body: JSON.stringify(data),
    };
    handleRequest<any>({
      endpoint,
      options,
      onSuccess: (response) => {
        setSucessMessage("Created");
        fetchAdminData();
        setIsLoading(false);
      },
      onError: () => {
        setIsError(true);
        setIsLoading(false);
        setErrorMessage("An error ocurred, please try again later");
      },
    });
  };

  const handleEditData = (
    endpoint: "users" | "counter" | "site" | "sitecounter" | "usersite",
    data: any
  ) => {
    let options: RequestInit = {
      method: "PUT",
      body: JSON.stringify(data),
    };
    handleRequest<any>({
      endpoint: `${endpoint}/${data.id}`,
      options,
      onSuccess: (response) => {
        setSucessMessage("Updated");
        fetchAdminData();
        setIsLoading(false);
      },
      onError: () => {
        setIsError(true);
        setIsLoading(false);
        setErrorMessage("An error ocurred, please try again later");
      },
    });
  };

  const handleDeleteData = (
    endpoint: "users" | "counter" | "site" | "sitecounter" | "usersite",
    id: string
  ) => {
    let options: RequestInit = {
      method: "DELETE",
    };
    handleRequest<any>({
      endpoint: `${endpoint}/${id}`,
      options,
      onSuccess: (response) => {
        setSucessMessage("Removed");
        fetchAdminData();
        setIsLoading(false);
      },
      onError: () => {
        setIsError(true);
        setIsLoading(false);
        setErrorMessage("An error ocurred, please try again later");
      },
    });
  };

  const handleExportExcel = (data: Object[]) => {
    try {
      let fileName = "report" + dayjs(new Date());
      exportFromJSON({ data, fileName, exportType: "csv" });
      setSucessMessage("File downloaded");
      setIsSuccess(true);
    } catch (e) {
      setErrorMessage("Please try again");
      setIsError(true);
    }
  };

  const handleDownloadEntries = () => {
    const data = entriesList.map((entry) => {
      return {
        Site: entry.site.name,
        Counter: entry.counter.description,
        Type: entry.in_out ? "in" : "out",
        Quantity: entry.quantity,
        "New Total": entry.new_value,
        "Issued at": dayjs(entry.created_at).format("MM/DD/YYYY HH:MM") || "",
        "Issued by": entry.user.username,
      };
    });
    handleExportExcel(data);
  };

  const fetchReport = (dateFrom: string, dateTo: string, site: string) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest<AdminDataProps>({
      endpoint: `report/?site=${idSite}&from=${dateFrom}&to=${dateTo}`,
      options,
      onSuccess: (response) => {
        if (response.data.length > 0) {
          handleExportExcel(response.data);
        } else {
          setIsError(true);
          setErrorMessage("No Records for this period of time");
        }
        setIsLoading(false);
      },
      onError: () => {
        setIsError(true);
        setIsLoading(false);
      },
    });
  };

  return (
    <DataContext.Provider
      value={{
        isLoading,
        isError,
        isSuccess,
        errorMessage,
        successMessage,
        idSite,
        counters,
        entriesList,
        siteList,
        adminData,
        userOptions,
        siteOptions,
        counterOptions,
        isModalReport,
        onOpenCloseModalReport: (value: boolean) => setIsModalReport(value),
        onChangeSite: (value: string) => setIdSite(value),
        handleDownloadEntries,
        fetchAdminData,
        fetchCounters,
        fetchHistoryBySite,
        fetchCounterBySite,
        handleAddOrSubstract,
        handleCreateData,
        handleEditData,
        handleDeleteData,
        fetchReport,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
