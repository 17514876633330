import React from "react";
import desktopLogo from "../../assets/images/company_logo.png";
import mobileLogo from "../../assets/images/company_logo_mobile.png";
import logo2 from "../../assets/images/company_logo_2.png";
import { Col, Grid, Row } from "antd";

export const Logo = ({ mobile = false }: { mobile?: boolean }) => {
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  const logo = xs || mobile ? mobileLogo : desktopLogo;
  return (
    <Row gutter={[2, 2]} justify="center" align="middle">
      <Col span={12}>
        <img src={logo} width={"50%"} alt="Company Logo" />
      </Col>
      <Col span={12}>
        <img src={logo2} width={"50%"} alt="Company Logo" />
      </Col>
    </Row>
  );
};
