import React from "react";
import { Layout, theme } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { DisplayComponent } from "./DisplayComponent";

interface Props {
  children: JSX.Element | JSX.Element[];
}
export const PublicLayout = ({ children }: Props) => {
  const {
    token: { colorFillSecondary },
  } = theme.useToken();
  return (
    <Layout>
      <Header
        style={{
          padding: 15,
          background: colorFillSecondary,
          minHeight: "15vh",
          marginBottom: "10px",
          textAlign: "center",
        }}
      ></Header>
      <DisplayComponent error={false} isLoading={false}>
        <Content style={{ padding: "100px 50px" }}>
          <div
            style={{
              padding: 0,
              minHeight: "50vh",
              textAlign: "center",
            }}
          >
            {children}
          </div>
        </Content>
      </DisplayComponent>
      <Footer
        style={{
          textAlign: "center",
          color: "#000",
        }}
      >
        ©Copyright Quattro Software, {new Date().getFullYear()}
      </Footer>
    </Layout>
  );
};
