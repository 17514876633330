import React, { useContext } from "react";
import { Col, Row, Table, Tag } from "antd";
import type { TableProps } from "antd";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { EntryProps } from "../../../types/interfaces";
import DataContext from "../../../context/DataContext";

const columns: TableProps<EntryProps>["columns"] = [
  {
    title: "Type",
    dataIndex: "in_out",
    key: "in_out",
    className: "center",
    render: (in_out) => (
      <>
        {in_out ? (
          <CaretUpOutlined style={{ color: "green", fontSize: "1.5rem" }} />
        ) : (
          <CaretDownOutlined style={{ color: "red", fontSize: "1.5rem" }} />
        )}
      </>
    ),
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    className: "center",
  },
  {
    title: "Total",
    dataIndex: "new_value",
    key: "new_value",
    className: "center",
  },
  {
    title: "Counter",
    key: "counter",
    dataIndex: "counter",
    className: "center",
    render: (params) => (
      <Tag style={{ color: "#000" }}>{params.description}</Tag>
    ),
  },
  {
    title: "Site",
    key: "site",
    dataIndex: "site",
    className: "center",
    render: (params) => <Tag style={{ color: "#000" }}>{params.name}</Tag>,
  },
  {
    title: "Issued at",
    dataIndex: "created_at",
    key: "created_at",
    className: "center",
    render: (params) => (
      <>{params ? dayjs(params).format("MM/DD/YYYY HH:MM") : ""}</>
    ),
  },
  {
    title: "Issued by",
    dataIndex: "user",
    key: "user",
    className: "center",
    render: (params) => <>{params.username}</>,
  },
];

export const HistoryTable: React.FC = () => {
  const { entriesList } = useContext(DataContext);
  const data: EntryProps[] = entriesList;
  return (
    <Row gutter={[12, 12]} justify="center">
      {/* <Col md={24} lg={8} xl={8}>
        <LineChart />
      </Col>
      <Col md={24} lg={8} xl={8}>
        <LineChart />
      </Col>
      <Col md={24} lg={8} xl={8}>
        <LineChart />
      </Col> */}
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={data}
          bordered
          size="small"
          scroll={{ x: 50 }}
          rowKey="id"
        />
      </Col>
    </Row>
  );
};
