import React from "react";
import {
  AdminDataProps,
  CounterListProps,
  CounterProps,
  EntryProps,
  ListProps,
  SiteProps,
  UserProps,
} from "../types/interfaces";
import { ADMIN_DATA_INITIAL_STATE } from "../utils/data";

interface Props {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  errorMessage: string;
  successMessage: string;
  idSite: string;
  counters: CounterProps[];
  entriesList: EntryProps[];
  siteList: ListProps[];
  adminData: AdminDataProps;
  userOptions: UserProps[];
  siteOptions: SiteProps[];
  counterOptions: CounterListProps[];
  isModalReport: boolean;
  onOpenCloseModalReport: (value: boolean) => void;
  onChangeSite: (value: string) => void;
  handleDownloadEntries: () => void;
  fetchAdminData: () => void;
  fetchCounters: (idUser: string) => void;
  fetchHistoryBySite: (siteArgs?: string, date?: string) => void;
  fetchCounterBySite: (idSite: string) => void;
  handleAddOrSubstract: (
    add: boolean,
    counter: CounterProps,
    idUser: string,
    qty: number
  ) => void;
  handleCreateData: (
    endpoint: "users" | "counter" | "site" | "sitecounter" | "usersite",
    data: any
  ) => void;
  handleEditData: (
    endpoint: "users" | "counter" | "site" | "sitecounter" | "usersite",
    data: any
  ) => void;
  handleDeleteData: (
    endpoint: "users" | "counter" | "site" | "sitecounter" | "usersite",
    data: any
  ) => void;
  fetchReport: (dateFrom: string, dateTo: string, idSite: string) => void;
}

const DataContext = React.createContext<Props>({
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: "",
  successMessage: "",
  idSite: "",
  counters: [],
  entriesList: [],
  siteList: [],
  adminData: ADMIN_DATA_INITIAL_STATE,
  userOptions: [],
  siteOptions: [],
  counterOptions: [],
  isModalReport: false,
  onOpenCloseModalReport: () => {},
  onChangeSite: () => {},
  handleDownloadEntries: () => {},
  fetchAdminData: () => {},
  fetchCounters: () => {},
  fetchHistoryBySite: () => {},
  fetchCounterBySite: () => {},
  handleAddOrSubstract: () => {},
  handleCreateData: () => {},
  handleEditData: () => {},
  handleDeleteData: () => {},
  fetchReport: () => {},
});

export default DataContext;
