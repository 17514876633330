import React, { useEffect } from "react";
import { Button, Col, Form, Input, Row } from "antd";
import { SiteTableProps } from "../../../../types/interfaces";
import { useForm } from "antd/es/form/Form";

interface Props {
  values: SiteTableProps;
  onFinish: (values: SiteTableProps) => void;
  onEdit: boolean;
}

export const InputForm = ({ values, onFinish, onEdit }: Props) => {
  const [form] = useForm();
  useEffect(() => {
    form.setFieldsValue(values);
  }, [form, values]);

  return (
    <>
      <Row gutter={16} justify="center">
        <Col xs={24} md={16}>
          <Form
            layout="vertical"
            initialValues={values}
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              label="Identifier"
              name="name"
              rules={[{ required: true, message: "Identifier" }]}
            >
              <Input name="name" placeholder="Identifier" value={values.name} />
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <Button type="primary" htmlType="submit" style={{ width: "50%" }}>
                {onEdit ? "Edit" : "Create"}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};
