import React, { useContext } from "react";
import { Flex, Select } from "antd";
import DataContext from "../../context/DataContext";
import { useLocation } from "react-router-dom";

export const AppHeader = () => {
  const { siteList, idSite, fetchCounterBySite } = useContext(DataContext);
  const location = useLocation();

  return (
    <Flex justify="right">
      {location.pathname === "/dashboard" && (
        <Select
          placeholder="Select a Site"
          value={idSite}
          style={{ marginRight: "5%" }}
          onChange={fetchCounterBySite}
          options={siteList}
          size="large"
        />
      )}
    </Flex>
  );
};
