import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select } from "antd";
import {
  EditableUserProps,
  UserTableProps,
} from "../../../../types/interfaces";
import { useForm } from "antd/es/form/Form";
import { EDITABLE_USER_INITIAL_STATE } from "../../../../utils/data";

interface Props {
  values: UserTableProps;
  onFinish: (values: UserTableProps) => void;
  onEdit: boolean;
}

const options = [
  { label: "user", value: 1 },
  { label: "admin", value: 2 },
];

export const UserForm = ({ values, onFinish, onEdit }: Props) => {
  const [form] = useForm();
  const [isSecondPassRequired, setIsSecondPassRequired] = useState(false);
  const [firstPassowrd, setFirstPassword] = useState("");
  const [newValues, setNewValues] = useState<EditableUserProps>(
    EDITABLE_USER_INITIAL_STATE
  );
  useEffect(() => {
    if (values) {
      const newData = {
        ...values,
        selectedRole: values.role.id,
        password1: "",
        password2: "",
      };
      setNewValues(newValues);
      form.setFieldsValue(newData);
    }
  }, [form, values, newValues]);

  useEffect(() => {
    if (firstPassowrd !== "") setIsSecondPassRequired(true);
  }, [firstPassowrd]);

  return (
    <>
      <Row gutter={16} justify="center">
        <Col xs={24} md={16}>
          <Form
            layout="vertical"
            initialValues={newValues}
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true, message: "Username" }]}
            >
              <Input
                name="username"
                placeholder="Username"
                value={newValues.username}
              />
            </Form.Item>
            <Form.Item
              label="Role"
              name="selectedRole"
              rules={[{ required: true, message: "Role" }]}
            >
              <Select
                placeholder="Add User"
                value={newValues.selectedRole}
                style={{ width: "100%" }}
                options={options}
              />
            </Form.Item>
            <Form.Item
              label="New Password"
              name="password1"
              rules={[
                {
                  required: !onEdit,
                },
              ]}
            >
              <Input.Password
                placeholder="Password"
                value={newValues.password1}
                onChange={(event) => setFirstPassword(event.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Confirm new Password"
              name="password2"
              dependencies={["password1"]}
              rules={[
                {
                  required: isSecondPassRequired,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password1") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Confirm Password"
                value={newValues.password2}
              />
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <Button type="primary" htmlType="submit" style={{ width: "50%" }}>
                {onEdit ? "Edit" : "Create"}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};
