import { Card, Statistic } from "antd";
import React from "react";
import Styles from "./DashboardComponents.module.css";

export const CardTotal = ({ total }: { total: number }) => {
  return (
    <Card
      hoverable
      bordered
      style={{
        backgroundColor: "#658BC3",
        textAlignLast: "center",
      }}
      className={Styles.counter_card_countainer}
    >
      <div className={Styles.counter_card} style={{ placeContent: "center" }}>
        <div>
          <Statistic
            value={total}
            valueStyle={{
              color: "#000",
              fontSize: "1.5rem",
              fontWeight: "bold",
              paddingBottom: "1rem",
            }}
            //   prefix={<UserOutlined />}
          />
          <div>Total</div>
        </div>
      </div>
    </Card>
  );
};
