import React, { useContext, useEffect, useRef, useState } from "react";
import { CounterProps } from "../../../types/interfaces";
import { Button, Card, Statistic, Tooltip } from "antd";
import { MinusOutlined, PlusOutlined, UserOutlined } from "@ant-design/icons";
import DataContext from "../../../context/DataContext";
import AuthContext from "../../../context/AuthContext";
import Styles from "./DashboardComponents.module.css";

interface Props {
  counter: CounterProps;
}

export const CounterCard = ({ counter }: Props) => {
  const { handleAddOrSubstract, isLoading } = useContext(DataContext);
  const { dataUser } = useContext(AuthContext);
  const [clickCountAdd, setClickCountAdd] = useState(1);
  const [clickCountSubstract, setClickCountSubstract] = useState(1);

  const timeoutId = useRef(undefined);

  useEffect(() => {
    return () => clearTimeout(timeoutId.current);
  }, []);

  const handleClickAdd = () => {
    setClickCountAdd((prev) => prev + 1);
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(async () => {
      handleAddOrSubstract(true, counter, dataUser.id, clickCountAdd);
      setClickCountAdd(1);
    }, 1000) as any;
  };

  const handleClickSubstract = () => {
    setClickCountSubstract((prev) => prev + 1);
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(async () => {
      handleAddOrSubstract(false, counter, dataUser.id, clickCountSubstract);
      setClickCountSubstract(1);
    }, 1000) as any;
  };
  return (
    <Card
      hoverable
      bordered
      style={{
        backgroundColor: "#FCBB75",
        textAlignLast: "center",
      }}
      className={Styles.counter_card_countainer}
    >
      <div className={Styles.counter_card}>
        <Tooltip title="Substract">
          <Button
            icon={<MinusOutlined />}
            type="primary"
            size="large"
            ghost={true}
            onClick={handleClickSubstract}
            disabled={isLoading || counter.counter_detail.current_value <= 0}
            className={Styles.button_counter}
          />
        </Tooltip>
        <div>
          <Statistic
            value={
              counter.counter_detail.current_value +
              clickCountAdd -
              clickCountSubstract
            }
            valueStyle={{
              color: "#000",
              fontSize: "1.5rem",
              fontWeight: "bold",
              paddingBottom: "1rem",
            }}
            prefix={<UserOutlined />}
          />
          <div>{counter.description}</div>
        </div>
        <Tooltip title="Add">
          <Button
            icon={<PlusOutlined />}
            type="primary"
            size="large"
            onClick={handleClickAdd}
            disabled={isLoading}
            className={Styles.button_counter}
          />
        </Tooltip>
      </div>
    </Card>
  );
};
