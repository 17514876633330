import { useContext } from "react";
import { CounterCard } from "./CounterCard";
import DataContext from "../../../context/DataContext";
import { Empty } from "antd";

export const DashboardCounter = () => {
  const { counters } = useContext(DataContext);

  return (
    <>
      {counters.length > 0 ? (
        counters?.map((counter) => (
          <CounterCard key={counter.id} counter={counter} />
        ))
      ) : (
        <Empty description="There are no counters to display" />
      )}
    </>
  );
};
