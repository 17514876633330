import React, { useContext, useState } from "react";
import { Button, Card, Modal, Popconfirm, Space, Table } from "antd";
import type { TableProps } from "antd";
import { UserTableProps } from "../../../../types/interfaces";
import DataContext from "../../../../context/DataContext";
import { UserForm } from "../forms/UserForm";
import { USER_LIST_INITIAL_STATE } from "../../../../utils/data";

export const UserTable = () => {
  const { adminData, handleDeleteData, handleEditData, handleCreateData } =
    useContext(DataContext);
  const [onEdit, setOnEdit] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [userToEdit, setUserToEdit] = useState<UserTableProps>(
    USER_LIST_INITIAL_STATE[0]
  );

  const onEditUser = (values: any) => {
    let user = { ...userToEdit, ...values, role_id: values.selectedRole };
    if (values.password1 !== "") {
      handleEditData("users", {
        ...user,
        password: values.password1,
      });
    } else {
      handleEditData("users", user);
    }
    setEditModal(false);
    setUserToEdit(USER_LIST_INITIAL_STATE[0]);
    setOnEdit(false);
  };
  const onCreateUser = (values: any) => {
    let user = {
      ...values,
      role_id: values.selectedRole,
      password: values.password1,
    };
    handleCreateData("users", user);
    setEditModal(false);
    setUserToEdit(USER_LIST_INITIAL_STATE[0]);
    setOnEdit(false);
  };

  const onDeleteUser = (user: UserTableProps) => {
    if (user) {
      handleDeleteData("users", user.id);
    }
  };

  const columns: TableProps<UserTableProps>["columns"] = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      className: "center",
      render: (_, row) => (
        <>
          <Button
            type="link"
            onClick={() => {
              setOnEdit(true);
              setUserToEdit(row);
              setEditModal(true);
            }}
          >
            {row.username}
          </Button>
        </>
      ),
    },
    {
      title: "Role",
      key: "role",
      className: "center",
      render: (_, { role }) => <>{role.description.toUpperCase()}</>,
    },
    {
      title: "Action",
      key: "action",
      className: "center",
      render: (_, record) => (
        <Space size="middle">
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => onDeleteUser(record)}
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Card
        title="Users"
        extra={
          <Button
            type="primary"
            onClick={() => {
              setOnEdit(false);
              setUserToEdit(USER_LIST_INITIAL_STATE[0]);
              setEditModal(true);
            }}
          >
            Add Users
          </Button>
        }
        bodyStyle={{ display: "none" }}
      ></Card>
      <Table
        columns={columns}
        dataSource={adminData.users}
        bordered
        rowKey={"id"}
        pagination={false}
        size="small"
        scroll={{ x: 50 }}
      />

      <Modal
        title={onEdit ? "Edit" : "Create"}
        open={editModal}
        onOk={(values) => {}}
        onCancel={() => setEditModal(false)}
        footer={null}
      >
        <UserForm
          values={userToEdit}
          onFinish={onEdit ? onEditUser : onCreateUser}
          onEdit={onEdit}
        />
      </Modal>
    </>
  );
};
