import React, { useContext } from "react";
import { ProtectedLayout } from "./ProtectedLayout";
import { Navigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

interface Props {
  admin: boolean;
  children: JSX.Element | JSX.Element[];
}
export const ProtectedRoute = ({ admin, children }: Props) => {
  const { validateToken } = useContext(AuthContext);
  if (!validateToken()) {
    return <Navigate to="/" replace />;
  }
  // if (admin && !isAdmin) {
  //   return <Navigate to={routes.dashboard} replace />;
  // }

  return <ProtectedLayout>{children}</ProtectedLayout>;
};
