import { Row } from "antd";
import { Logo } from "../../components/ui";
import { ColComponent } from "../../components/ui/ColComponent";
import { LoginForm } from "../../components/pages";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import Alert from "antd/es/alert/Alert";

export const LoginPage = () => {
  const { isError, errorMessage } = useContext(AuthContext);
  return (
    <>
      <Row gutter={[16, 16]} justify="center" align="middle">
        <ColComponent md={12} lg={12} xl={12}>
          <Logo mobile={false} />
          <LoginForm />
        </ColComponent>
      </Row>
      {isError && (
        <Alert
          message={errorMessage}
          banner
          type="error"
          closable
          className="alert"
        />
      )}
    </>
  );
};
