import { AdminPage, Dashboard, LoginPage } from "../../pages";
import { PageProps } from "../../types/interfaces";

export const routes = {
  login: "/",
  dashboard: "/dashboard",
  admin: "/adm",
  history: "report",
  help: "/help",
};

export const Pages: PageProps[] = [
  {
    path: routes.login,
    protected: false,
    admin: false,
    component: <LoginPage />,
  },
  {
    path: routes.admin,
    protected: true,
    admin: true,
    component: <AdminPage />,
  },
  // {
  //   path: routes.history,
  //   protected: true,
  //   admin: true,
  //   component: <HistoryPage />,
  // },
  {
    path: routes.dashboard,
    protected: true,
    admin: false,
    component: <Dashboard />,
  },
];
