import { Select, Tag } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { UserProps } from "../../../../types/interfaces";
import DataContext from "../../../../context/DataContext";
import AuthContext from "../../../../context/AuthContext";

interface Props {
  tags: UserProps[];
  site_id: string;
}

interface OptionProps {
  label: string;
  value: string;
  disabled: boolean;
}

const OPTIONS_INITIAL_STATE = [
  { label: "", value: "", disabled: false, site: "" },
];

export const SiteUserList = ({ tags, site_id }: Props) => {
  const { userOptions, handleEditData, handleCreateData } =
    useContext(DataContext);
  const { dataUser } = useContext(AuthContext);
  const [inputValue, setInputValue] = useState<OptionProps>(
    OPTIONS_INITIAL_STATE[0]
  );
  const [options, setOptions] = useState<OptionProps[]>(OPTIONS_INITIAL_STATE);

  useEffect(() => {
    const selectedOptions = tags.map((tag) => tag.id);
    let newOptions = userOptions.map((userOpt) => {
      return {
        label: userOpt.username,
        value: userOpt.id,
        disabled: selectedOptions.includes(userOpt.id),
      };
    });
    setOptions(newOptions);
  }, [userOptions, tags]);

  const handleDeleteTag = (removedTag: UserProps) => {
    removedTag.UserSite &&
      handleEditData("usersite", { ...removedTag.UserSite, active: false });
  };

  const handleInputConfirm = () => {
    if (inputValue && inputValue.value !== "") {
      const data = {
        users: inputValue.value,
        sites: site_id,
      };
      handleCreateData("usersite", data);
    }
    setInputValue(OPTIONS_INITIAL_STATE[0]);
  };

  const forMap = (tag: UserProps) => {
    const tagElem = (
      <Tag
        closable={!(dataUser.id === tag.id)}
        style={{ margin: "2px" }}
        onClose={(e) => {
          e.preventDefault();
          handleDeleteTag(tag);
        }}
      >
        {tag.username}
      </Tag>
    );

    return (
      <span key={tag.id} style={{ display: "inline-block" }}>
        {tagElem}
      </span>
    );
  };

  const tagChild = tags.map(forMap);

  return (
    <>
      <div style={{ marginBottom: 16 }}>{tagChild}</div>
      <div>
        {options.length > 0 && (
          <Select
            placeholder="Add User"
            size="small"
            value={inputValue}
            style={{ width: "8rem" }}
            onChange={(value, row) => setInputValue(row as OptionProps)}
            options={options}
            onBlur={handleInputConfirm}
          />
        )}
      </div>
    </>
  );
};
