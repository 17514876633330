import React, { useContext, useEffect } from "react";
import { HelpModal } from "../../components/utils";
import DataContext from "../../context/DataContext";
import { Alert, Col, Row } from "antd";
import {
  CounterTable,
  SiteCounterTable,
  SiteTable,
  UserTable,
} from "../../components/pages";

const helpContent = "Edit Sites, Counters and Users";

export const AdminPage = () => {
  const { fetchAdminData, isError, errorMessage, isSuccess, successMessage } =
    useContext(DataContext);
  // const { dataUser } = useContext(AuthContext);

  useEffect(() => {
    fetchAdminData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row gutter={[16, 16]} justify="center">
        <Col span={24}>
          <SiteTable />
        </Col>
        <Col span={24}>
          <CounterTable />
        </Col>
        <Col span={24}>
          <SiteCounterTable />
        </Col>
        <Col span={24}>
          <UserTable />
        </Col>
      </Row>
      <HelpModal content={helpContent} />
      {isError && (
        <Alert
          message={errorMessage}
          banner
          type="error"
          closable
          className="alert"
        />
      )}
      {isSuccess && (
        <Alert
          message={successMessage}
          banner
          type="success"
          closable
          className="alert"
        />
      )}
    </>
  );
};
