// import { theme } from "antd";

export const config = {
  token: {
    colorPrimary: "#0060A9",
    colorFillSecondary: "#F7941D",
    colorFill: "#0060A9",
  },
  // component: {
  //   Input: {
  //     activeBg: "#FFF",
  //   },
  // },
  // algorithm: theme.darkAlgorithm,
};
