import React, { useContext, useEffect, useState } from "react";
import { Button, Col, DatePicker, Form, Row, Select, TimePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useForm } from "antd/es/form/Form";
import DataContext from "../../../context/DataContext";

const format = "MM/DD/YYYY";

interface Props {
  site: string;
  dateFrom: Dayjs;
  dateTo: Dayjs;
  atFrom: Dayjs;
  atTo: Dayjs;
}

export const SearchForm = () => {
  const { fetchReport, siteList, idSite } = useContext(DataContext);
  const [values, setValues] = useState<Props>({
    site: "",
    dateFrom: dayjs().startOf("date"),
    dateTo: dayjs(),
    atFrom: dayjs().startOf("date"),
    atTo: dayjs().set("hour", 23).set("minute", 45),
  });

  useEffect(() => {
    if (idSite) {
      setValues((prev) => {
        return {
          ...prev,
          site: idSite,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [form] = useForm();
  useEffect(() => {
    form.setFieldsValue(values);
  }, [form, values]);

  const onGetReport = () => {
    const dateFromString = `${values.dateFrom
      .format("YYYY-MM-DD")
      .toString()} ${values.atFrom.format("HH:mm").toString()}`;
    const dateToString = `${values.dateTo
      .format("YYYY-MM-DD")
      .toString()} ${values.atTo.format("HH:mm").toString()}`;
    values.dateFrom && fetchReport(dateFromString, dateToString, values.site);
  };

  return (
    <>
      <Form
        name="search"
        layout="vertical"
        initialValues={values}
        form={form}
        onFinish={onGetReport}
      >
        <Row gutter={16} justify="center" align="middle">
          <Col span={16}>
            <Form.Item
              label="From"
              rules={[
                {
                  required: true,
                  message: "Please choose the Date From",
                },
              ]}
            >
              <DatePicker
                name="dateFrom"
                format={format}
                value={values.dateFrom && dayjs(values.dateFrom)}
                style={{ width: "100%" }}
                allowClear={false}
                onChange={(value) => {
                  setValues((prev) => {
                    return {
                      ...prev,
                      dateFrom: dayjs(value),
                    };
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="at">
              <TimePicker
                minuteStep={15}
                hourStep={1}
                format={"HH:mm"}
                value={values.atFrom}
                allowClear={false}
                onChange={(value, stringValue) => {
                  setValues((prev) => {
                    return {
                      ...prev,
                      atFrom: dayjs(value),
                    };
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} justify="center">
          <Col span={16}>
            <Form.Item
              label="To"
              rules={[
                {
                  required: true,
                  message: "Please choose the Date to",
                },
              ]}
            >
              <DatePicker
                name="dateTo"
                format={format}
                style={{ width: "100%" }}
                allowClear={false}
                value={values.dateTo && dayjs(values.dateTo)}
                onChange={(value) => {
                  setValues((prev) => {
                    return {
                      ...prev,
                      dateTo: dayjs(value),
                    };
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="at">
              <TimePicker
                minuteStep={15}
                hourStep={1}
                format={"HH:mm"}
                allowClear={false}
                value={values.atTo}
                onChange={(value) => {
                  setValues((prev) => {
                    return {
                      ...prev,
                      atTo: dayjs(value),
                    };
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} justify="center">
          <Col span={16}>
            <Form.Item
              label="Site"
              rules={[
                {
                  required: true,
                  message: "Please Select a site",
                },
              ]}
            >
              <Select
                placeholder="Select a Site"
                value={values.site}
                style={{ marginRight: "5%" }}
                onChange={(selected, value) => {
                  setValues((prev) => {
                    return {
                      ...prev,
                      site: selected,
                    };
                  });
                }}
                options={siteList}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} justify="center">
          <Col span={12}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                disabled={!values.dateFrom}
              >
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
