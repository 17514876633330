import React, { useContext, useState } from "react";
import { Button, Card, Modal, Popconfirm, Space, Table } from "antd";
import type { TableProps } from "antd";
import { SiteTableProps } from "../../../../types/interfaces";
import DataContext from "../../../../context/DataContext";
import { SITE_INITIAL_STATE } from "../../../../utils/data";
import { SiteCounterList } from "../list/SiteCounterList";
import { SiteUserList } from "../list/SiteUserList";
import { InputForm } from "../forms/InputForm";

export const SiteTable: React.FC = () => {
  const { adminData, handleEditData, handleCreateData, handleDeleteData } =
    useContext(DataContext);
  const [onEdit, setOnEdit] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [siteToEdit, setSiteToEdit] =
    useState<SiteTableProps>(SITE_INITIAL_STATE);
  useState<SiteTableProps>(SITE_INITIAL_STATE);

  const onEditSite = (site: SiteTableProps) => {
    handleEditData("site", { ...siteToEdit, ...site });
    setEditModal(false);
    setSiteToEdit(SITE_INITIAL_STATE);
    setOnEdit(false);
  };

  const onCreateSite = (site: SiteTableProps) => {
    handleCreateData("site", site);
    setEditModal(false);
    setSiteToEdit(SITE_INITIAL_STATE);
    setOnEdit(false);
  };

  const columns: TableProps<SiteTableProps>["columns"] = [
    {
      title: "Identifier",
      dataIndex: "name",
      key: "name",
      width: "15%",
      className: "center",
      render: (_, row) => (
        <>
          <Button
            type="link"
            onClick={() => {
              setOnEdit(true);
              setSiteToEdit(row);
              setEditModal(true);
            }}
          >
            {row.name}
          </Button>
        </>
      ),
    },
    {
      title: "Counters",
      key: "counters",
      width: "35%",
      className: "center",
      render: (_, { counters, id }) => (
        <>
          <SiteCounterList tags={counters} site_id={id} />
        </>
      ),
    },
    {
      title: "Users",
      key: "users",
      width: "35%",
      className: "center",
      render: (_, { users, id }) => (
        <>
          <SiteUserList tags={users} site_id={id} />
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      className: "center",
      render: (_, record) => (
        <Space size="middle">
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDeleteData("site", record.id)}
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Card
        title="Sites"
        extra={
          <Button
            type="primary"
            onClick={() => {
              setOnEdit(false);
              setSiteToEdit(SITE_INITIAL_STATE);
              setEditModal(true);
            }}
          >
            Add Sites
          </Button>
        }
        bodyStyle={{ display: "none" }}
      ></Card>
      <Table
        columns={columns}
        dataSource={adminData.sites}
        bordered
        rowKey={"id"}
        pagination={false}
        size="small"
        scroll={{ x: 50 }}
      />
      <Modal
        title={onEdit ? "Edit" : "Create"}
        open={editModal}
        onOk={(values) => {}}
        onCancel={() => setEditModal(false)}
        footer={null}
      >
        <InputForm
          values={siteToEdit}
          onFinish={onEdit ? onEditSite : onCreateSite}
          onEdit={onEdit}
        />
      </Modal>
    </>
  );
};
