import { FloatButton, Grid, Modal, Tooltip } from "antd";
import React, { ReactNode, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import {
  QuestionCircleOutlined,
  LogoutOutlined,
  UserOutlined,
  HomeOutlined,
  CloudDownloadOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import DataContext from "../../context/DataContext";
import { SearchForm } from "../../components/pages";

export const AppContent = ({ children }: { children: ReactNode }) => {
  const { onLogout, onOpenHelpModal, isAdmin } = useContext(AuthContext);
  const { isModalReport, onOpenCloseModalReport } = useContext(DataContext);
  const navigate = useNavigate();
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();

  const padding = xs ? "1rem" : "1rem 5rem";

  const adminButton = () => (
    <>
      <Tooltip title="Dashboard" placement="left">
        <FloatButton
          icon={<HomeOutlined />}
          type="primary"
          onClick={() => navigate("/dashboard")}
        />
      </Tooltip>
      <Tooltip title="Admin" placement="left">
        <FloatButton
          icon={<UserOutlined />}
          type="primary"
          onClick={() => navigate("/adm")}
        />
      </Tooltip>
      <Tooltip title="Report" placement="left">
        <FloatButton
          icon={<CloudDownloadOutlined />}
          type="primary"
          onClick={() => {
            onOpenCloseModalReport(true);
          }}
        />
      </Tooltip>
    </>
  );

  return (
    <>
      <div style={{ padding: padding, minHeight: "84vh" }}>{children}</div>
      <FloatButton.Group
        shape="circle"
        type="primary"
        style={{ right: 40, bottom: "6vh" }}
        trigger={xs ? "click" : undefined}
        icon={<SettingOutlined />}
      >
        {isAdmin && adminButton()}
        <Tooltip title="Help" placement="left">
          <FloatButton
            icon={<QuestionCircleOutlined />}
            type="primary"
            onClick={() => {
              onOpenHelpModal(true);
            }}
          />
        </Tooltip>
        <Tooltip title="Logout" placement="left">
          <FloatButton
            icon={<LogoutOutlined />}
            type="primary"
            onClick={onLogout}
          />
        </Tooltip>
      </FloatButton.Group>
      <Modal
        title={"Download Report"}
        open={isModalReport}
        onOk={() => {}}
        onCancel={() => onOpenCloseModalReport(false)}
        footer={null}
      >
        <SearchForm />
      </Modal>
    </>
  );
};
