import React, { useContext } from "react";
import { Button, Card, Popconfirm, Space, Table } from "antd";
import type { TableProps } from "antd";
import { CounterTableProps } from "../../../../types/interfaces";
import DataContext from "../../../../context/DataContext";
import AuthContext from "../../../../context/AuthContext";

export const SiteCounterTable: React.FC = () => {
  const { adminData, handleEditData } = useContext(DataContext);
  const { dataUser } = useContext(AuthContext);
  const onResetCounter = (values: CounterTableProps) => {
    handleEditData("sitecounter", {
      ...values,
      current_value: 0,
      reset: true,
      user_id: dataUser.id,
    });
  };

  const columns: TableProps<CounterTableProps>["columns"] = [
    {
      title: "Identifier",
      dataIndex: "counter",
      key: "counter",
      className: "center",
      render: (_, row) => <>{row.counter.description}</>,
    },
    {
      title: "Site",
      dataIndex: "site",
      key: "counter",
      className: "center",
      render: (_, { site }) => <>{site.name}</>,
    },
    {
      title: "Current Value",
      dataIndex: "current_value",
      key: "current_value",
      className: "center",
      render: (_, { current_value }) => <>{current_value}</>,
    },
    {
      title: "Action",
      key: "action",
      className: "center",
      render: (_, record) => (
        <Space size="middle">
          <Popconfirm
            title="Do you want to reset this counter?"
            onConfirm={() => onResetCounter(record)}
          >
            <Button type="link">Reset</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return (
    <>
      <Card title="Counters by site" bodyStyle={{ display: "none" }}></Card>
      <Table
        columns={columns}
        dataSource={adminData.counters}
        bordered
        rowKey={"id"}
        pagination={false}
        size="small"
        scroll={{ x: 50 }}
      />
    </>
  );
};
