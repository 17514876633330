import React, { useContext, useState } from "react";
import { Button, Card, Modal, Popconfirm, Space, Table } from "antd";
import type { TableProps } from "antd";
import { CounterListProps } from "../../../../types/interfaces";
import DataContext from "../../../../context/DataContext";
import { COUNTER_LIST_INITIAL_STATE } from "../../../../utils/data";
import { InputCounterForm } from "../forms/InputCounterForm";

export const CounterTable: React.FC = () => {
  const { counterOptions, handleCreateData, handleEditData, handleDeleteData } =
    useContext(DataContext);
  const [onEdit, setOnEdit] = useState(false);
  const [counterToEdit, setCounterToEdit] = useState<CounterListProps>(
    COUNTER_LIST_INITIAL_STATE[0]
  );
  const [editModal, setEditModal] = useState(false);

  const onEditCounter = (counter: CounterListProps) => {
    handleEditData("counter", { ...counterToEdit, ...counter });
    setEditModal(false);
    setCounterToEdit(COUNTER_LIST_INITIAL_STATE[0]);
    setOnEdit(false);
  };

  const onCreateCounter = (counter: CounterListProps) => {
    handleCreateData("counter", counter);
    setEditModal(false);
    setCounterToEdit(COUNTER_LIST_INITIAL_STATE[0]);
    setOnEdit(false);
  };

  const onDeleteCounter = (counter: CounterListProps) => {
    if (counter) {
      handleDeleteData("counter", counter.id);
    }
  };

  const columns: TableProps<CounterListProps>["columns"] = [
    {
      title: "Identifier",
      dataIndex: "counter",
      key: "counter",
      className: "center",
      render: (_, row) => (
        <>
          <Button
            type="link"
            onClick={() => {
              setCounterToEdit(row);
              setOnEdit(true);
              setEditModal(true);
            }}
          >
            {row.description}
          </Button>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      className: "center",
      render: (_, record) => (
        <Space size="middle">
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => onDeleteCounter(record)}
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return (
    <>
      <Card
        title="Counters"
        extra={
          <Button
            type="primary"
            onClick={() => {
              setOnEdit(false);
              setCounterToEdit(COUNTER_LIST_INITIAL_STATE[0]);
              setEditModal(true);
            }}
          >
            Add Counters
          </Button>
        }
        bodyStyle={{ display: "none" }}
      ></Card>
      <Table
        columns={columns}
        dataSource={counterOptions}
        bordered
        rowKey={"id"}
        pagination={false}
        size="small"
        scroll={{ x: 50 }}
      />
      <Modal
        title={onEdit ? "Edit" : "Create"}
        open={editModal}
        onOk={() => {}}
        onCancel={() => setEditModal(false)}
        footer={null}
      >
        <InputCounterForm
          values={counterToEdit}
          onFinish={onEdit ? onEditCounter : onCreateCounter}
          onEdit={onEdit}
        />
      </Modal>
    </>
  );
};
