import React, { useContext, useEffect, useState } from "react";
import { Alert, Row } from "antd";
import { ColComponent } from "../../components/ui/ColComponent";
import { CardTotal, DashboardCounter } from "../../components/pages";
import DataContext from "../../context/DataContext";
import { HelpModal } from "../../components/utils";
import AuthContext from "../../context/AuthContext";

const helpContent = "Select the site, then add or substract on each counter";

export const Dashboard = () => {
  const {
    isError,
    errorMessage,
    isSuccess,
    successMessage,
    fetchCounters,
    counters,
  } = useContext(DataContext);
  const { dataUser } = useContext(AuthContext);

  const [total, setTotal] = useState(0);

  useEffect(() => {
    let newTotal = 0;
    for (let i = 0; i < counters.length; i++) {
      newTotal += counters[i].counter_detail.current_value;
    }
    setTotal(newTotal);
  }, [counters]);

  useEffect(() => {
    if (dataUser.id) {
      fetchCounters(dataUser.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUser]);

  return (
    <div className="pt-2">
      <Row gutter={[12, 12]} justify="center">
        <ColComponent md={24} lg={16} xl={16}>
          <DashboardCounter />
          {counters.length > 0 && <CardTotal total={total} />}
        </ColComponent>
      </Row>
      <HelpModal content={helpContent} />
      {isError && (
        <Alert
          message={errorMessage}
          banner
          type="error"
          closable
          className="alert"
        />
      )}
      {isSuccess && (
        <Alert
          message={successMessage}
          banner
          type="success"
          closable
          className="alert"
        />
      )}
    </div>
  );
};
